@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;500;700&display=swap");
@import "outdated.css";

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    .frontpage-content-angle-tl {
        clip-path: polygon(0% 0, 95.5% 0%, 79.5% 100%, 0% 90%);
    }

    .frontpage-content-angle-r {
        clip-path: polygon(15.5% 0, 100% 0%, 100% 100%, 16% 100%, 1% 50%);
    }

    .frontpage-header-continuation-angle {
        clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0% 100%);
    }
}


@layer utilities {
    .port-btn-normal {
        @apply bg-secondary-500 text-neutral-100;
    }
    .port-btn-selected {
        @apply bg-accent-500 text-secondary-500;
    }
    .frontpage-content-part-time-br {
        @apply hidden md:inline;
    }
}

html {
    scroll-behavior: smooth;
}

#contact:target {
    animation-name: contact-attention;
    animation-duration: 800ms;
    animation-fill-mode: both;
}

@keyframes contact-attention {
    0% {
        transform: scale(1);
    }

    14% {
        transform: scale(1.04);
    }

    28% {
        transform: scale(1);
    }

    42% {
        transform: scale(1.04);
    }

    70% {
        transform: scale(1);
    }
}
